import { ApolloProvider } from "@apollo/client";
import { FontsLoader } from "@repo/ui/fontsLoader";
import { AppProps } from "next/app";
import Head from "next/head";

import { useApollo } from "src/hooks/useApollo";
import { StaffHandyChakraProvider } from "src/providers/StaffHandyChakraProvider";

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps.initialApolloState);

  return (
    <>
      <Head>
        <title>Speria for Staff Handy</title>
      </Head>

      <ApolloProvider client={client}>
        <StaffHandyChakraProvider>
          <FontsLoader>
            <Component {...pageProps} />
          </FontsLoader>
        </StaffHandyChakraProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
